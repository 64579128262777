// export const ClientID = `egTfKqb4TkiKDfWSqbSVgg`
// export const ClientSecret = `WqjFDfDATl5ZoF3GPLeiCyGuc6Ct6Q4s`
// export const RedirectURL = `${'http://localhost:3000'}/zoomhandler`
// prepay = 8
// postpay = 7
import moment from "moment";
export const getMediumRole = (param) => {
  switch (param) {
    case 1:
      return "Novice I";
    case 2:
      return "Intermediate I";
    case 3:
      return "Professional I";
    case 4:
      return "Novice II";
    case 5:
      return "Intermediate II";
    case 6:
      return "Professional II";
    case 7:
      return "Professional III";
      case 8:
      return "Support";
    default:
      return "N/A";
  }
};

export const getTimeImage = (item) => {
  let time = moment(`${item}`, "hh:mm A").format("HH:mm");
  if (time > "06:45" && time <= "18:45") {
    return false;
  } else {
    // if(time >= "18:00" && time < "06:00"){
    return true;
    // }
  }
}
export const studentLevelRoles = [
  { id: 1, name: "Novice" },
  { id: 2, name: "Intermediate" },
];
export const participantLevelRoles = [
  { id: 1, name: "Novice" },
  { id: 2, name: "Intermediate" },
  { id: 3, name: "Professional" },
];
export const mediumRoles = [
  { id: 1, name: "Novice I" },
  { id: 2, name: "Intermediate I" },
  { id: 3, name: "Professional I" },
  { id: 4, name: "Novice II" },
  { id: 5, name: "Intermediate II" },
  { id: 6, name: " Professional II" },
  { id: 7, name: "Professional III" },
  { id: 8, name: "Support" },
];
export const mediumRolesStudent = [
  { id: 1, name: "Novice I" },
  { id: 2, name: "Intermediate I" },
  { id: 4, name: "Novice II" },
  { id: 5, name: "Intermediate II" },
];
export const capitalizeFirstLetter = (str) => {
  return str != null && str.charAt(0).toUpperCase() + str.slice(1);
};

export const userType = (type) => {
  switch (type) {
    case 1:
      return "admin";
      break;
    case 2:
      return "medium";
      break;
    case 3:
      return "client";
      break;
    case 4:
      return "support";
      break;
    default:
      return "none";
  }
};

export const clientType = (type) => {
  switch (type) {
    case 1:
      return "free-client";
      break;
    case 2:
      return "outreact-client";
      break;
    case 3:
      return "paid-client";
      break;
    default:
      return "none";
  }
};

export const ADMIN = [
  {
    name: "Dashboard",
    route: "dashboard",
    imgDark: "/images/dashboard1.png",
    count: "",
    imgLight: "/images/dashboard2.png",
  },
  {
    name: "Mediums",
    route: "mediums",
    imgDark: "/images/calendar1.png",
    count: "",
    imgLight: "/images/calendar2.png",
  },
  {
    name: "Clients",
    route: "clients",
    imgDark: "/images/customer-b.png",
    count: "",
    imgLight: "/images/customer-w.png",
  },
  {
    name: "All Appointments",
    route: "appointments",
    imgDark: "/images/user22.png",
    count: "",
    imgLight: "/images/user222.png",
  },
  {
    name: "Survey Links",
    route: "survey",
    imgDark: "/images/hed-1.png",
    count: "",
    imgLight: "/images/hed-2.png",
  },
  {
    name: "Feedback",
    route: "feedbacks",
    imgDark: "/images/feedback1.png",
    count: "",
    imgLight: "/images/feedback2.png",
  },
  {
    name: "Requests",
    route: "requests",
    imgDark: "/images/user1.png",
    count: "",
    imgLight: "/images/user2.png",
  },
  {
    name: "Feedback Requests",
    route: "feedback-requests",
    imgDark: "/images/customer-b.png",
    count: "",
    imgLight: "/images/customer-w.png",
  },
  {
    name: "Operating Metrics",
    route: "/",
    imgDark: "/images/feedback1.png",
    count: "",
    imgLight: "/images/feedback2.png",
  },
  {
    name: "System Updates",
    route: "system-updates",
    imgDark: "/images/feedback1.png",
    count: "",
    imgLight: "/images/feedback2.png",
  },
  {
    name: "Services",
    route: "services",
    imgDark: "/images/hed-1.png",
    count: "",
    imgLight: "/images/hed-2.png",
  },
  {
    name: "Transactions",
    route: "transactions",
    imgDark: "/images/calendar1.png",
    count: "",
    imgLight: "/images/calendar2.png",
  },
  {
    name: "Referral Codes",
    route: "referrals",
    imgDark: "/images/calendar1.png",
    count: "",
    imgLight: "/images/calendar2.png",
  },
  {
    name: "On Demand Updates",
    route: "on-demand-updates",
    imgDark: "/images/feedback1.png",
    count: "",
    imgLight: "/images/feedback2.png",
  }
];
export const MEDIUM = [
  {
    name: "SESSIONS",
    route: "dashboard",
    imgDark: "/AllNewIcons/Color/Sesisons.svg",
    count: "",
    imgLight: "/AllNewIcons/White/Sesisons.svg",
    status: true,
    innerChild: [
      {
        name: "Make Booking",
        route: "booking",
        imgDark: "/images/calendar1.png",
        count: "",
        imgLight: "/images/calendar2.png",
      },
      {
        name: "Go On Demand Practice",
        route: "go-on-demand-practice",
        imgDark: "/AllNewIcons/Color/Ondemand.svg",
        count: "",
        imgLight: "/AllNewIcons/White/Ondemand.svg",
        headerName:"Special Event List"
      },
      {
        name: "Appointments",
        route: "appointments",
        imgDark: "/images/user22.png",
        count: "",
        imgLight: "/images/user222.png",
      },
      {
        name: "Feedback",
        route: "feedbacks",
        imgDark: "/images/feedback1.png",
        count: "",
        imgLight: "/images/feedback2.png",
      },
      {
        name: "Payments",
        route: "transactions",
        imgDark: "/AllNewIcons/Color/Payments.svg",
        count: "",
        imgLight: "/AllNewIcons/White/Payments.svg",

      },
      {
        name: "Pricing",
        route: "princing",
        imgDark: "/AllNewIcons/Color/dollar.png",
        count: "",
        imgLight: "/AllNewIcons/White/dollar.png",
      },
      //  {
      //   name: "Statistics",
      //   route: "dashboard",
      //   imgDark: "/images/fram11.png",
      //   count: "",
      //   imgLight: "/images/fram22.png",
      // }
    ]
  },
  {
    name: "SET-UP",
    route: "dashboard",
    imgDark: "/images/dashboard1.png",
    count: "",
    imgLight: "/images/dashboard2.png",
    status: true,
    innerChild: [
      {
        name: "Availability",
        route: "availability",
        imgDark: "/images/calendar1.png",
        count: "",
        imgLight: "/images/calendar2.png",
      },
      {
        name: "Daily View",
        route: "sessions",
        imgDark: "/AllNewIcons/Color/calendar1.svg",
        count: "",
        imgLight: "/AllNewIcons/White/calendar2.svg",
      },
      {
        name: "Refer Others",
        route: "refer-others",
        imgDark: "/AllNewIcons/Color/InDevelopment.svg",
        count: "",
        imgLight: "/AllNewIcons/White/InDevelopment.svg",
      },
      {
        name: "Integrations",
        route: "integration",
        imgDark: "/AllNewIcons/Color/Integration.svg",
        count: "",
        imgLight: "/AllNewIcons/White/Integration.svg",
      },
      {
        name: "Profile",
        route: "my-profile",
        imgDark: "/images/user1.png",
        count: "",
        imgLight: "/images/user2.png",
      },
    ]
  },
  {
    name: "IN DEVELOPMENT",
    route: "dashboard",
    imgDark: "/AllNewIcons/Color/InDevelopment.svg",
    count: "",
    imgLight: "/AllNewIcons/White/InDevelopment.svg",
    status: true,
    innerChild: [
      {
        name: "Go On Demand",
        route: "go-on-demand",
        imgDark: "/AllNewIcons/Color/Ondemand.svg",
        count: "",
        imgLight: "/AllNewIcons/White/Ondemand.svg",
      },

      {
        name: "Development",
        route: "/",
        imgDark: "/AllNewIcons/Color/MyDevelopment.svg",
        count: "",
        imgLight: "/AllNewIcons/White/MyDevelopment.svg",
      },
    ]
  },
  // {
  //   name: "My Availability",
  //   route: "availability",
  //   imgDark: "/images/calendar1.png",
  //   count: "",
  //   imgLight: "/images/calendar2.png",
  // },
  // {
  //   name: "My Sessions",
  //   route: "sessions",
  //   imgDark: "/images/user1.png",
  //   count: "",
  //   imgLight: "/images/user2.png",
  // },
  // {
  //   name: "My Appointments",
  //   route: "appointments",
  //   imgDark: "/images/user22.png",
  //   count: "",
  //   imgLight: "/images/user222.png",
  // },
  // {
  //   name: "My Profile",
  //   route: "my-profile",
  //   imgDark: "/images/user1.png",
  //   count: "",
  //   imgLight: "/images/user2.png",
  // },
  // {
  //   name: "Go On Demand",
  //   route: "go-on-demand",
  //   imgDark: "/images/fram11.png",
  //   count: "",
  //   imgLight: "/images/fram22.png",
  // },
  // {
  //   name: "My Feedback",
  //   route: "feedbacks",
  //   imgDark: "/images/feedback1.png",
  //   count: "",
  //   imgLight: "/images/feedback2.png",
  // },
  // {
  //   name: "My Development",
  //   route: "/",
  //   imgDark: "/images/fram11.png",
  //   count: "",
  //   imgLight: "/images/fram22.png",
  // },
  // {
  //   name: "Integrations",
  //   route: "integration",
  //   imgDark: "/images/feedback1.png",
  //   count: "",
  //   imgLight: "/images/feedback2.png",
  // },
  // {
  //   name: "Support",
  //   route: "",
  //   imgDark: "/images/hed-1.png",
  //   count: "",
  //   imgLight: "/images/hed-2.png",
  //   url: "https://appointments.verysoul.com/reserve/new-support",
  // },
  // {
  //   name: "Transactions",
  //   route: "transactions",
  //   imgDark: "/images/calendar1.png",
  //   count: "",
  //   imgLight: "/images/calendar2.png",
  // }
  // : Debrief / coaching sessions
];
// export const MEDIUM = [
//   {
//     name: "Dashboard",
//     route: "dashboard",
//     imgDark: "/images/dashboard1.png",
//     count: "",
//     imgLight: "/images/dashboard2.png",
//     status: true,
//     innerChild: [
//       {
//         name: "Dashboa1",
//         route: "my-profile",
//         imgDark: "/images/dashboard1.png",
//         count: "",
//         imgLight: "/images/dashboard2.png",
//       },
//       {
//         name: "Dashboar2",
//         route: "integration",
//         imgDark: "/images/dashboard1.png",
//         count: "",
//         imgLight: "/images/dashboard2.png",
//       }
//     ]
//   },
//   {
//     name: "My Availability",
//     route: "availability",
//     imgDark: "/images/calendar1.png",
//     count: "",
//     imgLight: "/images/calendar2.png",
//   },
//   {
//     name: "My Sessions",
//     route: "sessions",
//     imgDark: "/images/user1.png",
//     count: "",
//     imgLight: "/images/user2.png",
//   },
//   {
//     name: "My Appointments",
//     route: "appointments",
//     imgDark: "/images/user22.png",
//     count: "",
//     imgLight: "/images/user222.png",
//   },
//   {
//     name: "My Profile",
//     route: "my-profile",
//     imgDark: "/images/user1.png",
//     count: "",
//     imgLight: "/images/user2.png",
//   },
//   {
//     name: "Go On Demand",
//     route: "go-on-demand",
//     imgDark: "/images/fram11.png",
//     count: "",
//     imgLight: "/images/fram22.png",
//   },
//   {
//     name: "My Feedback",
//     route: "feedbacks",
//     imgDark: "/images/feedback1.png",
//     count: "",
//     imgLight: "/images/feedback2.png",
//   },
//   {
//     name: "My Development",
//     route: "/",
//     imgDark: "/images/fram11.png",
//     count: "",
//     imgLight: "/images/fram22.png",
//   },
//   {
//     name: "Integrations",
//     route: "integration",
//     imgDark: "/images/feedback1.png",
//     count: "",
//     imgLight: "/images/feedback2.png",
//   },
//   {
//     name: "Support",
//     route: "",
//     imgDark: "/images/hed-1.png",
//     count: "",
//     imgLight: "/images/hed-2.png",
//     url: "https://appointments.verysoul.com/reserve/new-support",
//   },
//   {
//     name: "Transactions",
//     route: "transactions",
//     imgDark: "/images/calendar1.png",
//     count: "",
//     imgLight: "/images/calendar2.png",
//   }
//   // : Debrief / coaching sessions
// ];
export const CLIENT = [
  {
    name: "Shortcuts",
    route: "shortcuts",
    imgDark: "/images/feedback1.png",
    count: "",
    imgLight: "/images/feedback2.png",
  },
  // {
  //   name: "Dashboard",
  //   route: "dashboard",
  //   imgDark: "/images/dashboard1.png",
  //   count: "",
  //   imgLight: "/images/dashboard2.png",
  // },
  {
    name: "My Appointments",
    route: "appointments",
    imgDark: "/images/calendar1.png",
    count: "",
    imgLight: "/images/calendar2.png",
  },
  {
    name: "My Profile",
    route: "my-profile",
    imgDark: "/images/user1.png",
    count: "",
    imgLight: "/images/user2.png",
  },
  {
    name: "Support",
    route: "",
    imgDark: "/images/hed-1.png",
    count: "",
    imgLight: "/images/hed-2.png",
    url: "https://support.verysoul.com/",
  },
  // {
  //   name: "My Feedback",
  //   route: "feedback",
  //   imgDark: "/images/user22.png",
  //count: "", //
  //imgLight: "/images/user222.png"),
  // },
];