import React, { useState } from 'react'

export default function Questions({ onCancelQuestions, startLoaderNow }) {
    const [error, setError] = useState(false);

    const [check4, setCheck4] = useState(false);
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [checkYes, setCheckYes] = useState(false);
    // const [checkNo, setCheckNo] = useState(false);

    const changeHandler = (e, setCheck, type) => {
        if (type === "radio") {
            setCheckYes(e.target.value);
        } else {
            setCheck(e.target.checked);
        }
    };

    const onClickHandler2 = () => {
        if (
            checkYes === "yes" ||
            (checkYes === "no")
        ) {
            // setStep(1);
            setError(false);
            startLoaderNow();
        } else {
            setError(true);
        }

    };
    return (
        <div class="container-fluid question-container">
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset>
                                <div class="form-card">
                                    <div class="row wrap">
                                        {/* <h2>Zoom Test</h2> */}
                                        <h3>Potential partners</h3>
                                        <div class="new secend-step">
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheckYes, "radio");
                                                    }}
                                                    value={"yes"}
                                                    checked={checkYes === "yes"}
                                                    name="yesNo"
                                                    type="radio"
                                                    id="yes"
                                                />
                                                <label htmlFor="yes">Anyone (except someone I’ve blocked)</label>
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    checked={checkYes === "no"}
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheckYes, "radio");
                                                    }}
                                                    value={"no"}
                                                    name="no"
                                                    type="radio"
                                                    id="no"
                                                />
                                                <label htmlFor="no">Only someone new (haven’t worked with recently)</label>
                                            </div>
                                            {error && (
                                                <div>
                                                    <p className="red ml-5">
                                                        *Please Select One Checkbox
                                                    </p>
                                                </div>
                                            )}
                                            <p className=" ml-5">  Instructions: Please stay near device for next 10 minutes</p>
                                        </div>

                                    </div>
                                </div>
                                <input
                                    onClick={onCancelQuestions}
                                    type="button"
                                    name="previous"
                                    class="previous action-button-previous"
                                    value="Cancel"
                                />
                                <input
                                    type="button"
                                    name="next"
                                    class="next action-button"
                                    value="Next"
                                    onClick={onClickHandler2}
                                />

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
