import React, { useEffect, useState } from "react";
import {
  doAProfessionalPayment,
  payWithPaypal,
  payWithStripe,
} from "../../services/client";
import PaymentPrices from "../payment/payment";
import { useSelector } from "react-redux";
import { s } from "@fullcalendar/core/internal-common";

export default function PayOptionsPopUp({
  from,
  practitionerInfo,
  bookAppointment,
  setCounterStatus,
  runCallback,
  activeSubSessionId,
  activeDurationId,
  activeAmount,
  activePay,
}) {
  const { bookingdate, bookingtime, paymentMethodAvailbale } = useSelector(
    (state) => state.client
  );
  const [paymentType, setPaymentType] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("20");

  const paymentMethodHandler = (type) => {
    if (from === "appointments") {
      runCallback(type);
    } else {
      let prices = activeAmount;
      // let prices = Object.values(
      //   JSON.parse(practitionerInfo.general.session_length)
      // )[0];
      let body = {
        medium_id: practitionerInfo.general.medium_id,
        session_type: 8,
        method: type,
        // medium_id: practitionerInfo.general.medium_id,
        amount: prices !== "" ? prices : 20,
        date: bookingdate,
        time: bookingtime,
        subsession_id: activeSubSessionId,
        duration: activeDurationId,
        appointment_type:'paybefore'
      };

      if (type === "paypal") {
        doAProfessionalPayment(body)
          .then((data) => {
            if (data.data.status) {
              window.open(data.data.data.approvedUrl, "_self");
              // openWaitingContainer();
            }
          })
          .catch(() => { });
      } else {
        doAProfessionalPayment(body)
          .then((data) => {
            if (data.data.status) {
              window.open(data.data.data.approvedUrl, "_self");
              // openWaitingContainer();
            }
          })
          .catch(() => { });
      }
    }
  };
  const openWaitingContainer = () => {
    setCounterStatus(true);
    window.loadModal2("#payoptionspopup", "hide");
    window.loadModal2("#WaitingTimerPopup", "show");
  };


  const checkPyamentType = (type, from, practitionerInfo, activePay) => {
    let status = false;

    if (type === "paypal") {
      if (from === undefined) {
        if (practitionerInfo !== null && practitionerInfo !== undefined &&
          practitionerInfo.general !== undefined &&
          practitionerInfo.general.is_paypal_integrated) {
          status = true;
        }
        else {
          status = false;
        }
      }
      else {
        if (activePay !== "" &&
          activePay.is_paypal_integrated) {
          status = true;

        }
        else {
          status = false;
        }

      }
    }
    if (type === "stripe") {
      if (from === undefined) {
        if (practitionerInfo !== null && practitionerInfo !== undefined &&
          practitionerInfo.general !== undefined &&
          practitionerInfo.general.is_stripe_integrated) {
          status = true;
        }
        else {
          status = false;
        }
      }
      else {
        console.log(activePay, "activePay")
        if (activePay !== "" &&
          activePay.is_stripe_integrated) {
          status = true;

        }
        else {
          status = false;
        }

      }
    }
    if (type === "square") {
      if (from === undefined) {
        if (practitionerInfo !== null && practitionerInfo !== undefined &&
          practitionerInfo.general !== undefined &&
          practitionerInfo.general.is_square_integrated) {
          status = true;
        }
        else {
          status = false;
        }
      }
      else {
        if (activePay !== "" &&
          activePay.is_square_integrated) {
          status = true;

        }
        else {
          status = false;
        }

      }
    }
    return status;
  }
  return (
    <div
  className="modal fade"
  id="payoptionspopup"
  role="dialog"
  data-backdrop="true"
>
  <div className="modal-dialog payment-dialog modal-dialog-preview">
    <div className="modal-content margin-top-300 border-waiting-time">
      <div className="modal-body modal-body-waiting-time">
        <button type="button" className="close" data-dismiss="modal">
          &times;
        </button>
        <div className="profile-video"></div>
        <h3 className="text-center mt-2">Pay With</h3>
        <h4 className="text-center mt-2">
          Select any of the following secure payment processors to finalize, and
          book your appointment.
        </h4>

        <div className="payment-new-wrapper mt-5">
          <div className="payment-options-container">
            {checkPyamentType("paypal", from, practitionerInfo, activePay) && (
              <div className="payment-option">
                <button
                  onClick={() => paymentMethodHandler("paypal")}
                  data-toggle="modal"
                  className="main-btn"
                >
                  <img src={"/integrationimages/paypal.png"} />
                </button>
                <p>(PayPal or credit/debit card)</p>
              </div>
            )}

            {checkPyamentType("stripe", from, practitionerInfo, activePay) && (
              <div className="payment-option">
                <button
                  onClick={() => paymentMethodHandler("stripe")}
                  data-toggle="modal"
                  className="main-btn"
                >
                  <img
                    className="stripe-img"
                    src={"/integrationimages/stripe.png"}
                  />
                </button>
                <p>(credit/debit card)</p>
              </div>
            )}

            {checkPyamentType("square", from, practitionerInfo, activePay) && (
              <div className="payment-option">
                <button
                  onClick={() => paymentMethodHandler("squareup")}
                  data-toggle="modal"
                  className="main-btn font-size-22"
                >
                  Square
                </button>
                <p>(credit/debit card)</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}
