import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/button";
import { setLoading } from "../../../../reducer/auth/auth";
import {
  deauthorizeCalendly,
  deauthorizePersonalCalendar,
  getZoomAuthUrl,
  LinkPersonalCalendar,
} from "../../../../services/uidata";
import {
  unLinkUserCalendar,
  unLinkUserZoom,
  updateGoogleSync,
} from "../../../../reducer/userSlice";
import "./integration.css";
import Card from "./card";
import Topbar from "../../../../components/topbar/topbar";
import {
  fetchLinkedCalendar,
  fetchLinkedZoomAccount,
  getLinkedEmail,
  getLinkedPaymentToken,
  savePaymentMedtods,
  savePaymentToForward,
  syncGoogleCalendarFinishSetup,
  syncGoogleCalendarFinishSetupConfirmation,
  updateGoogleCalendar,
  updateGoogleSyncSettings,
  verifyPaymentOtp,
} from "../../../../services/medium";
import { toast } from "react-toastify";
import GoToAvailability from "./gotoAvailabilityPopup";
import GoogleButton from "../../../../components/googlebutton";
import PaypalPdf from "./docs/PayPalIntegration.pdf";
import StripePdf from "./docs/StripeIntegration.pdf";
import Validation from "../../../register/client/validation";
import TickMark from "../../../../components/tickmark";
import ConfirmIfCalendarSelected from "./confirmIfCalendarSelected";
import FinishPopup from "./finishPopup";

export default function IntegrateModule() {
  const auth = useSelector((state) => state.auth);
  const {
    provider,
    google_calendar,
    id,
    google_availability_sync,
    show_payment_gateways,
    is_pay_forward

  } = useSelector((state) => state.user.user);
  const userLoginDetail = useSelector((state) => state.user);

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [status] = useState("zoom");
  const [tabOne, setTabOne] = useState(false);
  const [tabTwo, setTabTwo] = useState(false);
  const [tabThree, setTabThree] = useState(false);
  const [zoomconnectedemail, setZoomconnectedemail] = useState(false);
  const [zoomconnectedemailName, setZoomconnectedemailName] = useState(false);
  const [googleconnectedemail, setGoogleconnectedemail] = useState("");
  const [subcalendarname, setSubCalendarName] = useState("");
  const [calendarList, setCalendarList] = useState([]);
  const [str_pub_key, setstr_pub_key] = useState("");
  const [str_pri_key, setstr_pri_key] = useState("");
  const [paypal_pub_key, setpaypal_pub_key] = useState("");
  const [paypal_pri_key, setpaypal_pri_key] = useState("");
  const [squareup_access_token, setsquareup_access_token] = useState("");
  const [square_location_id, setsquare_location_id] = useState("");
  const [activePaymentTab, setActivePaymentTab] = useState("paypal");
  const [googleavailabilitysync, setGoogleAvailabilitySync] = useState(0);

  const [typestripe1, SetTypeStripe1] = useState(true);
  const [typestripe2, SetTypeStripe2] = useState(true);
  const [typepaypal1, SetTypePaypal1] = useState(true);
  const [typepaypal2, SetTypePaypal2] = useState(true);
  const [typesquare1, SetTypeSquare1] = useState(true);
  const [typesquare2, SetTypeSquare2] = useState(true);
  // const onLogoutHandler = () => {
  //   logoutUser(dispatch, navigate);
  // };

  const [verifiedStatus, setVerifiedStatus] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifiedTitle, setVerifiedTitle] = useState("Send Verification Code");
  const [verifiedError, setVerifiedError] = useState(false);
  const [verifiedMessage, setVerifiedMessage] = useState("");
  const [time, setTime] = useState(0);
  const [showResend, setshowResend] = useState(false);
  const [resend, setResend] = useState(0);
  const [Suggested_amount, setSuggested_amount] = useState('');
  const [pay_forward, setpay_forward] = useState(0);
  const [show_pay_forward, setshow_pay_forward] = useState(false);

  const [is_payment_integrated, setIs_payment_integrated] = useState(false);

  const [confirmCalendarSetup, setconfirmCalendarSetup] = useState(false);
  const [zoomconnecteErrorMessage, setzoomconnecteErrorMessage] = useState(false);

  function getPathFromUrl(url) {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("status");
    newUrl.searchParams.delete("message");
    window.history.replaceState({}, document.title, newUrl.href);
  }

  useEffect(() => {
    // window.loadModal2("#ConfirmIfCalendarSelected", "show");
    setGoogleAvailabilitySync(google_availability_sync === 1 ? true : false);
  }, [google_availability_sync]);
  useEffect(() => {
    console.log(
      google_calendar,
      "google_calendargoogle_calendargoogle_calendar"
    );
  }, [google_calendar]);

  useEffect(() => {
    setshow_pay_forward(is_pay_forward);
    let calendar_status = searchParams.get("status");
    let linked_status = searchParams.get("message");
    if (calendar_status && linked_status === "GoogleAccountLinked") {
      fetchLinkedCalendar()
        .then((data) => {
          console.log(data);
          if (data.data.status) {
            let info = data.data.data.calendar_list.map((item) => {
              if (item.id === "primary") {
                return {
                  ...item,
                  status: true,
                };
              } else {
                return {
                  ...item,
                  status: false,
                };
              }
            });
            setCalendarList(info);
            window.loadModal2("#integration-modal", "show");

            getLinkedEmail()
              .then((data) => {
                if (data.data.status) {
                  setGoogleconnectedemail(
                    data.data.data.google_connected_email
                  );
                  setSubCalendarName(data.data.data.google_calendar_id);
                  // checkConnections(data.data.data)
                }
              })
              .catch(() => { });
          }
        })
        .catch(() => { });
    } else {
      getLinkedEmail()
        .then((data) => {
          if (data.data.status) {
            setGoogleconnectedemail(data.data.data.google_connected_email);
            setSubCalendarName(data.data.data.google_calendar_id);
            // setVerifiedStatus(data.data.data.payment_integrated === "both" || data.data.data.payment_integrated === "stripe" || data.data.data.payment_integrated === "paypal" ? false : true);

            if (data.data.data.is_stripe) {
              setstr_pri_key("32");
              setstr_pub_key("32");
            }
            if (data.data.data.is_paypal) {
              setpaypal_pri_key("32");
              setpaypal_pub_key("323");
            }
            if (data.data.data.is_squareup) {
              setsquareup_access_token("32");
              setsquare_location_id('323');
            }
            // checkConnections(data.data.data)
          }
          console.log("integrated", data);
        })
        .catch(() => { });
    }
    if (calendar_status && linked_status === "ZoomAccountLinked") {
      // fetchLinkedZoomAccount()
      //   .then((data) => {
      //     if (data.data.status) {
      getLinkedEmail()
        .then((data) => {
          getPathFromUrl();
          // if (data.data.status) {
          setZoomconnectedemail(data.data.data.is_zoom_connected);
          setzoomconnecteErrorMessage(data.data.data.is_zoom_connected ? false : true)
          setZoomconnectedemailName(data.data.data.zoom_connected_email)
          // checkConnections(data.data.data)
          // }
        })
        .catch(() => { });
      //   }
      // })
      // .catch(() => { });
    } else {
      getLinkedEmail()
        .then((data) => {
          if (data.data.status) {
            setZoomconnectedemail(data.data.data.is_zoom_connected);
            setzoomconnecteErrorMessage(data.data.data.is_zoom_connected ? false : true)
            setZoomconnectedemailName(data.data.data.zoom_connected_email)
            // checkConnections(data.data.data)
          }
        })
        .catch(() => { });
    }
  }, []);

  const checkConnections = (data) => {
    if (googleconnectedemail !== null && zoomconnectedemail) {
      confirmIfCalendarSelectedHandler()
    }
  };
  const deauthorize = () => {
    dispatch(setLoading(true));
    deauthorizeCalendly()
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          dispatch(unLinkUserZoom());
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const deauthorizeCalendar = () => {
    dispatch(setLoading(true));
    deauthorizePersonalCalendar()
      .then((data) => {
        dispatch(setLoading(false));
        console.log(data.data, "data.data");
        if (data.data.status) {
          setGoogleconnectedemail("");
          setSubCalendarName("");
          dispatch(unLinkUserCalendar());
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const onProceedHandler = () => {
    console.log(status, "zoom", provider);
    if ((status === "zoom" && provider === null) || provider === "") {
      getAuthUrl();
    }
    if (status === "zoom" && provider === "Zoom") {
      deauthorize();
    }
  };
  const onLinkCalendar = () => {
    if (google_calendar === null || google_calendar === "") {
      linkcalendar();
    } else {
      deauthorizeCalendar();
    }
  };
  const getAuthUrl = () => {
    dispatch(setLoading(true));
    getZoomAuthUrl("integration")
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          window.open(data.data.data.redirect_url, "_self");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const linkcalendar = () => {
    dispatch(setLoading(true));
    LinkPersonalCalendar(id, "integration")
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          window.open(data.data.data.url, "_self");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  // const makeActive = (state) => {
  //   setStatus(state);
  // };
  const closeTabOne = () => {
    setTabOne(!tabOne);
    setTabTwo(false);
    setTabThree(false);
  };
  const closeTabTwo = () => {
    setTabOne(false);
    setTabThree(false);
    setTabTwo(!tabTwo);
  };
  const closeTabThree = () => {
    setTabOne(false);
    setTabTwo(false);
    setTabThree(!tabThree);
  };
  const selectCalendarHandler = (item) => {
    let info = calendarList.map((data) => {
      if (data.id == item.id) {
        return {
          ...data,
          status: true,
        };
      } else {
        return {
          ...data,
          status: false,
        };
      }
    });
    setCalendarList(info);
  };
  const saveSelectedCalendar = () => {
    let id = calendarList.filter((data) => data.status);
    let body = {
      google_calendar_id: id[0].id,
      google_calendar_name: id[0].summary,
    };
    console.log(id);
    updateGoogleCalendar(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Calendar Updated Succesfully", {
            position: "top-center",
          });
          getPathFromUrl();
          window.loadModal2("#integration-modal", "hide");
          setSubCalendarName(body.google_calendar_name);
          checkConnections();
        }
      })
      .catch(() => { });
  };
  const unlinkHnalder = (id) => {
    if (id === "ConfirmDeleteModalZoom") {
      onProceedHandler();
    } else {
      onLinkCalendar();
    }
  };
  const onOpenPayment = () => {
    window.loadModal2("#payment-modal", "show");
  };
  const paymentChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "str_pub_key") {
      setstr_pub_key(value);
    }
    if (name === "str_pri_key") {
      setstr_pri_key(value);
    }
    if (name === "paypal_pub_key") {
      setpaypal_pub_key(value);
    }
    if (name === "paypal_pri_key") {
      setpaypal_pri_key(value);
    }
    if (name === "squareup_access_token") {
      setsquareup_access_token(value);
    }
    if (name === "square_location_id") {
      setsquare_location_id(value);
    }
  };

  const savePaymentMethods = (info) => {
    console.log(info)
    console.log(paypal_pub_key, paypal_pri_key)
    if (info === "stripe") {
      if (str_pub_key === str_pri_key) {
        toast.error("Wrong strpe Keys", { position: "top-center" });
        return;

      }
    }
    if (info === "paypal") {
      if (paypal_pub_key === paypal_pri_key) {
        toast.error("Wrong paypal Keys", { position: "top-center" });
        return;

      }
    }
    if (info === "square") {
      if (squareup_access_token === square_location_id) {
        toast.error("Wrong square Keys", { position: "top-center" });
        return;

      }
    }

    let body = {
      str_pub_key: str_pub_key,
      str_pri_key: str_pri_key,
      paypal_pub_key: paypal_pub_key,
      paypal_pri_key: paypal_pri_key,
      squareup_access_token: squareup_access_token,
      square_location_id: square_location_id
    };

    savePaymentMedtods(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Updated", { position: "top-center" });
          window.loadModal2("#payment-modal", "hide");
        }
      })
      .catch(() => { });
  };

  const savePaymentToForwardHandler = () => {

    let body = {
      medium_id: id,
      pay_forward: pay_forward,
      Suggested_amount: Suggested_amount
    }
    savePaymentToForward(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Updated", { position: "top-center" });
        }
      }).catch(() => {

      })

  }
  const changePayItforwardHandler = (e) => {
    let checked = e.target.checked;
    setpay_forward(checked ? 1 : 0)
    console.log(checked)
  }
  const changeAvailabilityHandler = (e) => {
    e.stopPropagation();

    let body = {
      enable_google_sync: googleavailabilitysync ? 0 : 1,
    };
    updateGoogleSyncSettings(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(updateGoogleSync(googleavailabilitysync ? 0 : 1));
          setGoogleAvailabilitySync(!googleavailabilitysync);
          toast.success("Google sync updated", { position: "top-center" });
        }
      })
      .catch(() => {
        toast.error("Something went wrong", { position: "top-center" });
      });
  };
  const changePassType = (type) => {
    if (type === 1) {
      SetTypeStripe1(!typestripe1);
    } else if (type === 2) {
      SetTypeStripe2(!typestripe2);
    } else if (type === 3) {
      SetTypePaypal1(!typepaypal1);
    } else if (type === 4) {
      SetTypePaypal2(!typepaypal2);
    } else if (type === 5) {
      SetTypeSquare1(!typesquare1);
    } else if (type === 6) {
      SetTypeSquare2(!typesquare2);
    }
  };
  const clearPaymentField = () => {
    if (activePaymentTab === "paypal") {
      let body = {
        str_pub_key: str_pub_key,
        str_pri_key: str_pri_key,
        paypal_pub_key: "",
        paypal_pri_key: "",
        squareup_access_token: squareup_access_token,
        square_location_id: square_location_id
      };

      savePaymentMedtods(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Cleared Payments", { position: "top-center" });
            window.loadModal2("#payment-modal", "hide");
          }
        })
        .catch(() => { });
      setpaypal_pri_key("");
      setpaypal_pub_key("");
    } else if (activePaymentTab === "stripe") {
      let body = {
        str_pub_key: "",
        str_pri_key: "",
        paypal_pub_key: paypal_pub_key,
        paypal_pri_key: paypal_pri_key,
        squareup_access_token: squareup_access_token,
        square_location_id: square_location_id
      };

      savePaymentMedtods(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Cleared Payments", { position: "top-center" });
            window.loadModal2("#payment-modal", "hide");
          }
        })
        .catch(() => { });
      setstr_pri_key("");
      setstr_pub_key("");

    }
    else {
      let body = {
        str_pub_key: str_pub_key,
        str_pri_key: str_pri_key,
        paypal_pub_key: paypal_pub_key,
        paypal_pri_key: paypal_pri_key,
        squareup_access_token: "",
        square_location_id: "",
      };

      savePaymentMedtods(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Cleared Payments", { position: "top-center" });
            window.loadModal2("#payment-modal", "hide");
          }
        })
        .catch(() => { });
      setsquareup_access_token("");
      setsquare_location_id("");
    }
  };
  const reSendVerificationCodeHandler = () => {
    sendVerificationCodeHandler();
    setResend(2);
    setTimeout(() => {
      setResend(0);
    }, 4000);
  };
  const handleChangeVerification = (e) => {
    setVerificationCode(e.target.value);
  };

  const sendVerificationCodeHandler = () => {
    let body = {
      verification_code: verificationCode,
    };
    verifyPaymentOtp(body)
      .then((data) => {
        if (!data.data.status && data.data.message == "OTP sent Successfully") {
          setVerifiedTitle("Verify");
          setVerifiedMessage("Verification Code sent successfully");
          setVerifiedError(false);
        }
        if (!data.data.status && data.data.message == "Invalid OTP") {
          setVerifiedTitle("Verify");
          setVerifiedMessage("Invalid Verification Code");
          setVerifiedError(true);
        }
        if (data.data.status && data.data.message == "Code Verified") {
          getPaymentToken();
          setVerifiedTitle("Verified");
          setVerifiedMessage(data.data.message);
          setshow_pay_forward(true);
          setVerifiedError(false);
          setVerified(true);
          setVerifiedStatus(true);
        }
      })
      .catch((data) => {
        setVerifiedMessage("Please Try Again");
        setVerifiedError(true);
      });
  };
  const getPaymentToken = () => {
    getLinkedPaymentToken()
      .then((data) => {
        if (data.data.status) {
          setpaypal_pri_key(data.data.data.paypal_pri_key);
          setpaypal_pub_key(data.data.data.paypal_pub_key);
          setstr_pri_key(data.data.data.str_pri_key);
          setstr_pub_key(data.data.data.str_pub_key);
          setsquareup_access_token(data.data.data.squareup_access_token);
          setsquare_location_id(data.data.data.square_location_id);
          setSuggested_amount(data.data.data.suggested_amount)
          setpay_forward(data.data.data.pay_forward)

        }
      })
      .catch(() => { });
  };

  const confirmIfCalendarSelectedHandler = () => {
    console.log(confirmCalendarSetup);
    // if (confirmCalendarSetup) {
    let body = {
      user_id: id
    }
    syncGoogleCalendarFinishSetup(body).then((data) => {
      if (data.data.status) {
        window.loadModal2("#ConfirmIfCalendarSelected", "show");
        // window.loadModal2("#ConfirmIfCalendarSelected", "hide");
        // window.loadModal2("#FinishPopup", "show");
      }

    }).catch(() => {

    })
    // }

  }
  const finishPopupHandler = () => {
    if (confirmCalendarSetup) {
      let body = {
        user_id: id
      }
      syncGoogleCalendarFinishSetupConfirmation(body).then((data) => {
        if (data.data.status) {
          window.loadModal2("#ConfirmIfCalendarSelected", "hide");
          window.loadModal2("#gotoavailability", "show");
        }

      }).catch(() => {

      })
    }
  }
  const cancelFinishPopupHandler = () => {
    window.loadModal2("#FinishPopup", "hide");
    window.loadModal2("#gotoavailability", "show");
  }
  return (
    <>
      {auth.role === "medium" && (provider === "" || provider === null) && (
        <Topbar />
      )}
      <div className="integaction-all-page min-height-100vh">
        <div className="integaction-page-saction">
          <div className="container">
            <div className="row">
              <div className="col-md-4  _payment _tbd">
                <Card
                  id={"ConfirmDeleteModalZoom"}
                  src={"/integrationimages/zoom.png"}
                  title={
                    provider && provider === "Zoom"
                      ? "UnLink"
                      : "Link your Zoom"
                  }
                  unlinkHnalder={unlinkHnalder}
                  buttonType={provider && provider === "Zoom" ? true : false}
                  onClick={closeTabOne}
                  tab={tabOne}
                  type={"zoom"}
                  tabNo={1}
                  zoomconnectedemail={zoomconnectedemail}
                  zoomconnectedemailName={zoomconnectedemailName}
                  name={"Zoom"}
                />
                {zoomconnecteErrorMessage &&
                  <div class="w-100">
                    <div className="zoom-message-box mt-2">
                      Your Zoom account has become disconnected from our system.
                      <br />
                      <br />
                      Use the gold button next to “Link your Zoom” then select the green “Continue” button to regain access to our system and to be able to take future bookings
                    </div>
                  </div>
                }
                {tabOne && (
                  <div class="box-popup-inte intepopup_one">

                    <br />
                    <br />
                    <h3>
                      <b>Instructions for linking your zoom: </b>
                    </h3>

                    <div className="border-instructions">
                      <p className="red margin-unset font-size-17">
                        Note : Be sure to click only this zoom box when prompted
                      </p>

                      <p className="alow-p">
                        <span className="red-circle">
                          {" "}
                          <img
                            src="/integrationimages/check.png"
                            alt="check"
                          />{" "}
                        </span>
                        Allow this app to use my shared access permissions.{" "}
                        <a href="javascript:void(0);">Learn More</a>
                      </p>

                      <p>
                        By giving this app access, it will be take actions on
                        your behalf for other users, based on your permissions
                        and what the app can view or manage.
                      </p>
                      <strong>By Clicking all,</strong>
                      <ul>
                        <li>
                          You agree to
                          <a href="javascript:void(0);">
                            Zoom App marketplace terms of use
                          </a>
                        </li>
                        <li>
                          You give permission to this app to use the information
                          it's allowed to access in accordance with their
                          <a href="javascript:void(0);">
                            Terms & Privacy policy.
                          </a>
                        </li>
                      </ul>
                      <p>You can remove this app at any time in My Apps.</p>
                    </div>
                    <div class="button-close-proced">
                      <button
                        onClick={() => setTabOne(false)}
                        class="cancell cancel_first_link_zoom"
                      >
                        Cancel
                      </button>
                      <button onClick={onProceedHandler} class="proced">
                        Continue
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4  _payment _tbd">
                <Card
                  id={"ConfirmDeleteModalCalendar"}
                  src={"/integrationimages/Calendar.png"}
                  title={
                    google_calendar === "" || google_calendar === null
                      ? "Link your personal calendar"
                      : "UnLink"
                  }
                  // tbd
                  unlinkHnalder={unlinkHnalder}
                  buttonType={
                    google_calendar === "" || google_calendar === null
                      ? false
                      : true
                  }
                  onClick={closeTabTwo}
                  tab={tabTwo}
                  tabNo={2}
                  googleconnectedemail={googleconnectedemail}
                  subcalendarname={subcalendarname}
                  changeAvailabilityHandler={changeAvailabilityHandler}
                  googleavailabilitysync={googleavailabilitysync}
                  name={"Calendar"}
                />
                {tabTwo && (
                  <div class="box-popup-inte intepopup_one">
                    <h3>
                      <b>Instructions for linking your calendar: </b>
                    </h3>
                    <div className="border-instructions">
                      <p className="red margin-unset font-size-17">
                        Note : Be sure to click both Google boxes when prompted
                      </p>
                      <img
                        className="width-440px"
                        src={"/integrationimages/instruct.png"}
                      />
                    </div>
                    <div class="button-close-proced">
                      <button
                        onClick={() => setTabTwo(false)}
                        class="cancell cancel_first_link_zoom"
                      >
                        Cancel
                      </button>
                      <GoogleButton
                        onClick={onLinkCalendar}
                        className={"proced"}
                      />
                      {/* <button onClick={onLinkCalendar} class="proced">
                        Continue
                      </button> */}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`col-md-4 _payment  _tbd ${show_payment_gateways ? "" : "opacity-2"
                  }`}
              >
                <Card
                  src={"/integrationimages/Card.png"}
                  title={"Link your payment gateway"}
                  // tbd
                  onClick={show_payment_gateways ? closeTabThree : () => { }}
                  tab={tabThree}
                  tabNo={3}
                  name={"Payment"}
                  str_pub_key={str_pub_key}
                  str_pri_key={str_pri_key}
                  paypal_pub_key={paypal_pub_key}
                  paypal_pri_key={paypal_pri_key}
                  square_location_id={square_location_id}
                  squareup_access_token={squareup_access_token}
                  show_payment_gateways={show_payment_gateways}
                  show_pay_forward={show_pay_forward}
                />
                {tabThree && (
                  <div class="box-popup-inte intepopup_one">
                    {!verifiedStatus && (
                      <div className="payment-verify-token">
                        <div className="ffrom-fild ">
                          <div className="">
                            <label className="text-center">
                              Verify OTP to Edit Payment Methods
                            </label>
                            <div className="d-flex">
                              {verifiedTitle !== "Send Verification Code" && (
                                <input
                                  onChange={handleChangeVerification}
                                  className="height-45 margin-right-30"
                                  value={verificationCode}
                                  type="text"
                                  id="codeverification"
                                  name="codeverification"
                                  placeholder="Code"
                                />
                              )}

                              <Button
                                status={0}
                                type="button"
                                className={`resend-btn w-unset height-45 min-width-130px ${verifiedTitle === "Verify" ? "bg-green" : ""
                                  }`}
                                onClick={
                                  time === 0
                                    ? sendVerificationCodeHandler
                                    : () => { }
                                }
                                title={verifiedTitle}
                              />
                              {(verifiedMessage !== "Verified" ||
                                showResend) && (
                                  <>
                                    {resend === 1 && (
                                      <Button
                                        status={0}
                                        type="button"
                                        className={`resend-btn w-unset height-45 min-width-130px ml-2`}
                                        onClick={reSendVerificationCodeHandler}
                                        title={"Resend"}
                                      />
                                    )}
                                    {resend === 2 && <TickMark />}
                                  </>
                                )}
                            </div>
                            {/* {verifiedTitle == "Verify" && (
                            <>
                              {" "}
                              <div className="text-left font-size-11">
                                {" "}
                                <i>*</i>Delivery may take up to 10 minutes.
                              </div>
                              <br />
                              <div className="red text-left mt-1">
                                <i>*</i>Please expect significant mail delivery delays if using Yahoo
                                Mail.
                              </div>
                            </>
                          )} */}
                            {verifiedTitle == "Verified" && (
                              <div className="text-left font-size-11">
                                {" "}
                                <i>*</i>Email Succesfully Verfied
                              </div>
                            )}
                            <div
                              className={`text-left mt-1 ${verifiedError ? "red" : "green"
                                }`}
                            >
                              {verifiedMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <h3><b>Instructions for linking your calendar: </b></h3>
                    <div className="border-instructions">
                      <p className="red margin-unset font-size-17">Note : Be sure to click both Google boxes when prompted</p>
                      <img className="width-440px" src={"/integrationimages/instruct.png"} />
                    </div> */}
                    {verifiedStatus && (
                      <>
                        <div className="payment-new-wrapper">
                          <div className="payment-new-container">
                            <button
                              onClick={() => setActivePaymentTab("paypal")}
                              className={`main-btn ${activePaymentTab === "paypal"
                                ? "payment-active-tab"
                                : ""
                                }`}
                            >
                              {" "}
                              <img src={"/integrationimages/paypal.png"} />
                            </button>
                            <button
                              onClick={() => setActivePaymentTab("stripe")}
                              className={`main-btn ${activePaymentTab === "stripe"
                                ? "payment-active-tab"
                                : ""
                                }`}
                            >
                              {" "}
                              <img
                                className="stripe-img"
                                src={"/integrationimages/stripe.png"}
                              />
                            </button>
                            <button
                              onClick={() => setActivePaymentTab("square")}
                              className={`main-btn ${activePaymentTab === "square"
                                ? "payment-active-tab"
                                : ""
                                }`}
                            >
                              {" "}

                              <img
                                className="height-28"
                                src={"/integrationimages/square-black.jpg"}
                              />
                            </button>
                            <button
                              onClick={() => setActivePaymentTab("payitforward")}
                              className={`main-btn paytoforward-btn ${activePaymentTab === "payitforward"
                                ? "payment-active-tab"
                                : ""
                                }`}
                            >
                              {" "}
                              <div className="d-flex align-items-center w-100 justify-content-around ">
                                <img
                                  className="width-unset"
                                  src={"/images/Union-icon.png"}
                                />
                                <span className="color-black mt-1">Pay It Forward</span>
                              </div>
                            </button>

                          </div>
                        </div>

                        {activePaymentTab === "paypal" && (
                          <div className="d-flex">
                            <div>
                              <div className="">
                                <div>
                                  <label className="payment-methods-container-inner-label">
                                    Public key
                                  </label>
                                  <div className="payment-methods-container-inner">
                                    <input
                                      value={paypal_pub_key}
                                      name="paypal_pub_key"
                                      className="mt-2"
                                      onChange={paymentChangeHandler}
                                      type={typepaypal1 ? "password" : "text"}
                                    />
                                    <div className="icon-eys-left">
                                      <img
                                        className={`pointer ${typepaypal1 ? "" : "openeye"
                                          }`}
                                        onClick={() => changePassType(3)}
                                        src={
                                          typepaypal1
                                            ? "images/eye.png"
                                            : "localImages/openeye.svg"
                                        }
                                        alt="eye"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="payment-methods-container-inner-label">
                                    Private key
                                  </label>
                                  <div className="payment-methods-container-inner">
                                    <input
                                      value={paypal_pri_key}
                                      name="paypal_pri_key"
                                      type={typepaypal2 ? "password" : "text"}
                                      className="mt-2"
                                      onChange={paymentChangeHandler}
                                    />
                                    <div className="icon-eys-left">
                                      <img
                                        className={`pointer ${typepaypal2 ? "" : "openeye"
                                          }`}
                                        onClick={() => changePassType(4)}
                                        src={
                                          typepaypal2
                                            ? "images/eye.png"
                                            : "localImages/openeye.svg"
                                        }
                                        alt="eye"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="payment-save-btn">
                                <Button
                                  className={
                                    "resend-btn w-unset height-45 min-width-130px"
                                  }
                                  status={0}
                                  onClick={() => savePaymentMethods("paypal")}
                                  title="Save"
                                />
                              </div>
                            </div>
                            <div className="mt-5 ml-5">
                              <h3>
                                <b>
                                  <a
                                    href={
                                      "https://support.verysoul.com/portal/en/kb/articles/paypal-gateway-integration"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Click Here{" "}
                                  </a>
                                  for instructions for linking your paypal
                                </b>
                              </h3>
                            </div>
                          </div>
                        )}
                        {activePaymentTab === "stripe" && (
                          <div className="d-flex">
                            <div>
                              <div>
                                <div>
                                  <label className="payment-methods-container-inner-label">
                                    Public key
                                  </label>
                                  <div className="payment-methods-container-inner">
                                    <input
                                      value={str_pub_key}
                                      name="str_pub_key"
                                      type={typestripe1 ? "password" : "text"}
                                      className="mt-2"
                                      onChange={paymentChangeHandler}
                                    />
                                    <div className="icon-eys-left">
                                      <img
                                        className={`pointer ${typestripe1 ? "" : "openeye"
                                          }`}
                                        onClick={() => changePassType(1)}
                                        src={
                                          typestripe1
                                            ? "images/eye.png"
                                            : "localImages/openeye.svg"
                                        }
                                        alt="eye"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="payment-methods-container-inner-label">
                                    Secret Key
                                  </label>
                                  <div className="payment-methods-container-inner">
                                    <input
                                      value={str_pri_key}
                                      name="str_pri_key"
                                      type={typestripe2 ? "password" : "text"}
                                      className="mt-2"
                                      onChange={paymentChangeHandler}
                                    />
                                    <div className="icon-eys-left">
                                      <img
                                        className={`pointer ${typestripe2 ? "" : "openeye"
                                          }`}
                                        onClick={() => changePassType(2)}
                                        src={
                                          typestripe2
                                            ? "images/eye.png"
                                            : "localImages/openeye.svg"
                                        }
                                        alt="eye"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="payment-save-btn">
                                <Button
                                  className={
                                    "resend-btn w-unset height-45 min-width-130px "
                                  }
                                  status={0}
                                  onClick={() => savePaymentMethods("stripe")}
                                  title="Save"
                                />
                              </div>
                            </div>
                            <div className="mt-5 ml-5">
                              <h3>
                                <b>
                                  <a
                                    href={
                                      "https://support.verysoul.com/portal/en/kb/articles/stripe"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Click Here{" "}
                                  </a>
                                  for instructions for linking your stripe
                                </b>
                              </h3>
                            </div>
                          </div>
                        )}

                        {/***************** */}
                        {activePaymentTab === "square" && (
                          <div className="d-flex">
                            <div>
                              <div>
                                <div>
                                  <label className="payment-methods-container-inner-label">
                                    Square Access Token
                                  </label>
                                  <div className="payment-methods-container-inner">
                                    <input
                                      value={squareup_access_token}
                                      name="squareup_access_token"
                                      type={typesquare1 ? "password" : "text"}
                                      className="mt-2"
                                      onChange={paymentChangeHandler}
                                    />
                                    <div className="icon-eys-left">
                                      <img
                                        className={`pointer ${typesquare1 ? "" : "openeye"
                                          }`}
                                        onClick={() => changePassType(5)}
                                        src={
                                          typesquare1
                                            ? "images/eye.png"
                                            : "localImages/openeye.svg"
                                        }
                                        alt="eye"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="payment-methods-container-inner-label">
                                    Square Location Id
                                  </label>
                                  <div className="payment-methods-container-inner">
                                    <input
                                      value={square_location_id}
                                      name="square_location_id"
                                      type={typesquare2 ? "password" : "text"}
                                      className="mt-2"
                                      onChange={paymentChangeHandler}
                                    />
                                    <div className="icon-eys-left">
                                      <img
                                        className={`pointer ${typesquare2 ? "" : "openeye"
                                          }`}
                                        onClick={() => changePassType(6)}
                                        src={
                                          typesquare2
                                            ? "images/eye.png"
                                            : "localImages/openeye.svg"
                                        }
                                        alt="eye"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="payment-save-btn">
                                <Button
                                  className={
                                    "resend-btn w-unset height-45 min-width-130px "
                                  }
                                  status={0}
                                  onClick={() => savePaymentMethods("square")}
                                  title="Save"
                                />
                              </div>
                            </div>
                            <div className="mt-5 ml-5">
                              <h3>
                                <b>
                                  <a
                                    href={
                                      "https://support.verysoul.com/portal/en/kb/articles/square-integration"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Click Here{" "}
                                  </a>
                                  for instructions for linking your square
                                </b>
                              </h3>
                              <div className="red font-size-17 line-height-20px mt-5">Note: Square requires users to set prices in the currency of the<br /> country where their account was created. Please ensure that the <br />currency selected in our system matches the currency for the<br /> country of your account’s creation.
                              </div>
                            </div>
                          </div>
                        )}
                        {/***************** */}
                        {activePaymentTab === "payitforward" && (
                          <div className="d-flex align-items-center w-100 justify-content-around mt-4">
                            <div>
                              <div>
                                <div className='d-flex  mt-2'>

                                  <input
                                    value={1}
                                    onChange={changePayItforwardHandler}
                                    name='suggestedprice'
                                    checked={pay_forward == 1 ? 'checked' : ''}
                                    type='checkbox'
                                    id='suggestedprice'
                                    className='radio-btn-starter mr-2 radio-btn-payitforward' />
                                  <label htmlFor='suggestedprice' className='margin-unset width-470px font-size-19 line-height-24px'>I would like any contributions for my sessions to be directed back to VerySoul, to help Pay it Forward, and I understand that these amounts are not recoverable by me </label>
                                </div>
                              </div>
                              {/* <div>
                                <label className="payment-methods-container-inner-label">
                                  Suggested Amount
                                </label>
                                <div className="payment-methods-container-inner">
                                  <input
                                    value={Suggested_amount}
                                    name="suggested_amount"
                                    type={'number'}
                                    className="mt-2"
                                    onChange={(e) => setSuggested_amount(e.target.value)}
                                  />

                                </div>
                              </div> */}
                              <div
                              //  className="payment-save-btn"
                              >
                                {/* <Button
                                  className={
                                    "resend-btn w-unset height-45 min-width-130px "
                                  }
                                  status={0}
                                  onClick={() => savePaymentMethods("square")}
                                  title="Save"
                                /> */}
                                <div class="button-close-proced mt-4">
                                  <button
                                    onClick={() => savePaymentToForwardHandler("payitforward")}
                                    class="resend-btn cancel_first_link_zoom"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={() => setTabThree(false)}
                                    class="cancell cancel_first_link_zoom"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>

                          </div>
                        )}

                        {/**************** */}
                        {activePaymentTab !== "payitforward" && <div class="button-close-proced">
                          <button
                            onClick={() => setTabThree(false)}
                            class="cancell cancel_first_link_zoom"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={clearPaymentField}
                            class="cancell cancel_first_link_zoom"
                          >
                            Clear
                          </button>
                        </div>}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="modal fade" id="integration-modal" role="dialog">
          <div className="modal-dialog modal-dialog-preview">
            <div className="modal-content">
              <div className="modal-body text-align-unset">
                <h4 className="text-center mt-2">Choose your calendar</h4>
                {calendarList.map((item) => (
                  <div
                    onClick={() => selectCalendarHandler(item)}
                    className={`calendar-card pointer ${item.status ? "calendar-card-norder" : ""
                      }`}
                  >
                    {/* <p>{item.etag}</p>
                    <p>{item.id}</p> */}
                    <p className="letter-spacing-1px">
                      <b>Calendar Name </b>: {item.summary}
                    </p>
                    <p className="letter-spacing-1px">
                      <b>Time Zone</b> : {item.timeZone}
                    </p>
                  </div>
                ))}
                <div className="text-center">
                  <Button
                    className={"resend-btn w-unset height-45 min-width-130px"}
                    status={0}
                    onClick={saveSelectedCalendar}
                    title="Save Calendar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*******************/}

        <GoToAvailability />
      </div>
      <ConfirmIfCalendarSelected
        confirmCalendarSetup={confirmCalendarSetup}
        setconfirmCalendarSetup={setconfirmCalendarSetup}
        finishPopupHandler={finishPopupHandler}
      />
      {/* <FinishPopup
        finishPopupHandler={finishPopupHandler}
        cancelFinishPopupHandler={cancelFinishPopupHandler}
      /> */}
    </>
  );
}
