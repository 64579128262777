import React, { useState } from "react";
import { setReminderOndemand } from "../../../services/medium";
import { toast } from "react-toastify";

export default function ReminderPopUp({ activeEvent }) {
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    // const { id  } = useSelector((state) => state.user.user);

    const setPaymentReminder = () => {
        if (value == "") {
            setError(true);
            return;
        }
        let body = {
            event_id: activeEvent.id,
            reminder_time: value,
        };
        setReminderOndemand(body)
            .then((data) => {
                console.log(data)
                if (data.data.data) {
                    toast.success(data.data.success, { position: 'top-center' });
                    setValue("")
                    setTimeout(() => {

                        window.loadModal2("#ReminderPopUp", "hide");

                    }, 300);
                }
            })
            .catch(() => { });
    };

    return (
        <div>
            <div
                id="ReminderPopUp"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
            // data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content p-5">
                        <h3>Set Reminder Before</h3>
                        <div className="width-100 mt-3">
                            <select
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    setError(false);
                                }}
                                className="width-345px p-3"
                            >
                                <option value={""} disabled>
                                    Set Reminder for
                                </option>
                                <option value={"15 Minutes"}>15 Minutes</option>
                                <option value={"30 Minutes"}>30 Minutes</option>
                                <option value={"1 Hour"}>1 Hour</option>
                                <option value={"2 Hour"}>2 Hour</option>
                            </select>
                            <span className="text-left error">{error && "*Required"}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-5">
                            <div onClick={setPaymentReminder} className="width-175-px pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center">
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
