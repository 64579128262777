import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Formik } from "formik";
import Select from "react-select";

import "./edit.css";
import {
  getCountry,
  getLanguages,
  getTimezones,
} from "../../../services/uidata";
import { setLoading } from "../../../reducer/auth/auth";
import { getLegalAndServicesForClient, getProfileBasicInfo } from "../../../services/admin";
import { updateInitialProfile } from "../../../services/profile";
import {
  capatilizeLetter,
  filterVisibleTimeZones,
  screenNameHandler,
} from "../../../utils/helpers";
import PhoneInputField from "../../../components/Inputfields/phoneinput";
import ToggleTestMode from "../Subcomponents/toogletestmode";
import { updateLegalprivacy } from "../../../services/client";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import CopyComponent from "../../../components/copyComponent";
const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  screen_name: "",
  whatsapp: "",
  country: "",
  languages: [],
  timezone: "",
  limit: "",
  referral_code: "",
  email: "",
  test_mode: 0,
  agree_to_legal_terms_and_conditionss: 0,
  opt_in_out: '',
  questioner: "",
  zoom_verification: 0
};
const list = [
  { id: 0, name: "Info" },
  { id: 1, name: "Questions" }
]
export default function ClientEdit() {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');


  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formLanguages, setFormLanguages] = useState([]);
  const [newTimezoneData, setNewTimezoneData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [TimezoneData, setTimezoneData] = useState([]);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [valueUpdated, setVlaueUpdated] = useState(true);
  const [listArray, setListArray] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [questioner, setquestioner] = useState("");
  const [queryValue, setQueryValue] = useState('');
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [query3, setQuery3] = useState('');
  const [query4, setQuery4] = useState('');
  // const [userCountryCode, setUserCountryCode] = useState("US");


  useEffect(() => {
    // setTimeout(() => {
    // getProfile(params.id);
    getProfile(params.id);
    getLegalData(params.id)
    updateTimeZone();
    updateCountries();
    updateLanguage();
    // }, 2000);

    setQueryValue(status)
    setQuery1(searchVal)
    setQuery2(searchName)
    setQuery3(page)
    setQuery4(rowperpage)
    setTimeout(() => {
      removeQueryParams()
    }, 2000);
  }, []);

  const removeQueryParams = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("view");
    newUrl.searchParams.delete("search");
    newUrl.searchParams.delete("searchName");
    newUrl.searchParams.delete("page");
    newUrl.searchParams.delete("rowperpage");
    // newUrl.searchParams.delete("id");
    window.history.replaceState({}, document.title, newUrl.href);

  };

  const clearForm = () => {
    initialValues.firstName = "";
    initialValues.lastName = "";
    initialValues.phone = "";
    initialValues.screen_name = "";
    initialValues.whatsapp = "";
    initialValues.country = "";
    initialValues.languages = [];
    initialValues.timezone = "";
    initialValues.limit = "";
    initialValues.referral_code = "";
    initialValues.email = "";
    initialValues.test_mode = 0;
    initialValues.zoom_verification = 0;
  }
  const updateCountries = () => {
    getCountry()
      .then((data) => {
        setCountryData(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        setFormLanguages(
          data.data.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const updateTimeZone = () => {
    getTimezones()
      .then((data) => {
        if (data.data.status) {
          setTimezoneData(data.data.data);
          setNewTimezoneData(filterVisibleTimeZones(data.data.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const reloadTimeZones = (refresh) => {
    getTimezones()
      .then((data) => {
        setTimezoneData(data.data.data);
        setNewTimezoneData(filterVisibleTimeZones(data.data.data));
        if (refresh == "refresh") {
          toast.success("TimeZones Refreshed", { position: "top-center" });
        }
      })
      .catch((err) => console.log(err));
  }
  const RegisterUser = async (formData) => {
    dispatch(setLoading(true));
    const {
      firstName,
      lastName,
      phone,
      whatsapp,
      screen_name,
      country,
      languages,
      timezone,
      limit,
      referral_code,
      email,
      test_mode,
      agree_to_legal_terms_and_conditionss,
      opt_in_out,
      comments,
      zoom_verification
    } = formData;

    const elementData = languages.filter(function (element) {
      return element !== undefined;
    });

    const lang = await elementData.map((item) => {
      return item.id;
    });

    let item = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      whatsapp: whatsapp,
      country: country,
      languages: lang,
      timezone: timezone,
      userId: params.id,
      screen_name: screen_name,
      limit: limit,
      referral_code: referral_code,
      email: email,
      test_mode: test_mode,
      comments: comments,
      zoom_verification: zoom_verification ? 1 : 0,
    };
    let item1 = {
      user_id: params.id,

      agree_to_legal_terms_and_conditionss: agree_to_legal_terms_and_conditionss ? 1 : 0,
      opt_in_out: opt_in_out === "Yes" ? 1 : 0,


    }
    updateInitialProfile(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Profile Updated", { position: "top-center" });
          getProfile(params.id);
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });

    updateLegalprivacy(item1).then(() => { }).catch(() => { })
  };
  const getProfile = (id) => {
    dispatch(setLoading(true));
    getProfileBasicInfo(id)
      .then((data) => {
        dispatch(setLoading(false));

        if (data.data.status) {
          const {
            country,
            first_name,
            last_name,
            screen_name,
            languages,
            phone,
            whatsapp,
            timezone,
            limit,
            referral_code,
            email,
            test_mode,
            comments,
            questioner,
            zoom_verification
          } = data.data.data;
          initialValues.firstName = first_name;
          initialValues.lastName = last_name;
          initialValues.country = country;
          initialValues.phone = phone;
          initialValues.languages = updateIncomingHandler(
            languages,
            "languages"
          );
          initialValues.whatsapp = whatsapp;
          initialValues.timezone = timezone.id;
          initialValues.screen_name = screen_name;
          initialValues.limit = limit;
          initialValues.referral_code = referral_code;
          initialValues.email = email;
          initialValues.test_mode = test_mode;
          initialValues.comments = comments;
          initialValues.zoom_verification = zoom_verification == 1 ? true : false;

          data.data.data && data.data.data.questioner ? setListArray(list) : setListArray([])


          setquestioner(questioner);
          // setProfileSection(data.data.data);
          setDataUpdate(true)
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
        setDataUpdate(false)
      });
  };

  const checkTimeZone = (timezone) => {
    // console.log(initialValues.timezone , "timezone" , TimezoneData ,"TimezoneData")
    if (initialValues.timezone !== "" && TimezoneData.length > 0) {
      let time = TimezoneData.filter(
        (item) => item.id === initialValues.timezone
      );
      let status = time[0].status;
      console.log(time[0])
      let info = time[0];
      let newinfo = newTimezoneData;
      // console.log(info)
      if (status === 0) {
        let time = newTimezoneData.filter(
          (item) => item.id === initialValues.timezone
        );
        console.log(time, !time.length, "ytime")
        if (!time.length) {
          newinfo.push({ ...info,status:1 });
          setNewTimezoneData(newinfo);
        }
      }
    }
  };
  const updateIncomingHandler = (items, type) => {
    let incomingArray;
    if (type === "languages") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.language_id,
          value: item.language_id,
          label: item.lanugage_name,
        };
      });
    }
    return incomingArray;
  };

  const capatilizeLetters = (e, name, setFieldValue, uservalues) => {
    let values = e.target.value;
    const input = capatilizeLetter(values);
    setFieldValue(name, input);

    if (uservalues.firstName !== "") {
      setFieldValue(
        "screen_name",
        screenNameHandler(
          name === "firstName" ? e.target.value : uservalues.firstName,
          name === "lastName" ? e.target.value : uservalues.lastName
        )
      );
    }
    if (e.target.name === "firstName" && e.target.value === "") {
      setFieldValue("screen_name", "");
    }
  };

  const countryChangeHandler = (e, name, setFieldValue) => {
    let value = e.target.value;
    // if (value) {
    //   setUserCountryCode(getCountryCode(value ,countryData));
    // }
    setFieldValue(name, value);
  };

  const getLegalData = (id) => {
    getLegalAndServicesForClient(id).then((data) => {
      console.log(data.data)
      if (data.data.status) {
        setVlaueUpdated(true)
        console.log(data.data.data);
        let info = data.data.data
        initialValues.agree_to_legal_terms_and_conditionss = info.agree_to_legal_terms_and_conditionss === 1 ? true : false;
        initialValues.opt_in_out = info.opt_in_out === 1 ? 'Yes' : info.opt_in_out === 0 ? 'No' : '';
      }
    }).catch(() => { });

  }
  return (
    <>
      <div className="back-btn-profile ml-2 container-fluid">
        <span
          className="back-handler pointer"
          onClick={() => {
            clearForm();
            status === null ? navigate(-1) : navigate(`/clients?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&pagintion=${'change'}`)
          }}
        >
          <img src="../images/arrow-back.png" alt="go back" />
          Go Back
        </span>
      </div>
      <div className={listArray.length > 0 ? "all-medium-page" : ''}>
        <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />

        <ul className="tabs-content">
          <TabPanel id={0} activeTab={activeTab}>
            {dataUpdate ? <>  <div className="login-register-saction registernew">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="login-saction-right register-right register-clint">
                      <div className="form-l---r">
                        <Formik
                          initialValues={initialValues}
                          validate={(values) => {
                            const errors = {};
                            if (!values.firstName) errors.firstName = "Required";
                            if (!values.lastName) errors.lastName = "Required";
                            // // if (values.phone && !/^[0-9]+$/.test(values.phone)) {
                            // //   errors.phone = "Invalid Phone";
                            // // }
                            // if (values.whatsapp && !/^[0-9]+$/.test(values.whatsapp)) {
                            //   errors.whatsapp = "Invalid whatsapp";
                            // }

                            if (
                              values.website &&
                              !/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
                                values.website
                              )
                            ) {
                              errors.website = "Invalid Website";
                            }
                            if (!values.country) errors.country = "Required";
                            if (!values.timezone) errors.timezone = "Required";

                            if (!values.languages) errors.languages = "Required";
                            if (!values.email) errors.email = "Required";
                            return errors;
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            RegisterUser(values);
                            // setOpen(true);
                            // setTimeout(() => {
                            //   alert(JSON.stringify(values, null, 2));
                            //   setSubmitting(false);
                            // }, 400);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <form onSubmit={handleSubmit}>

                              <div className="from-fild">
                                <div className="icon">
                                  <img alt="user" src="/images/user.png" />
                                </div>
                                <label htmlFor="firstName">First Name*</label>
                                <input
                                  className={`${errors.firstName &&
                                    touched.firstName &&
                                    errors.firstName
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  onChange={(e) =>
                                    capatilizeLetters(
                                      e,
                                      "firstName",
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  onBlur={handleBlur}
                                  value={values.firstName}
                                  type="text"
                                  id="firstName"
                                  name="firstName"
                                  placeholder="First Name"
                                />
                                <span className="error">
                                  {errors.firstName &&
                                    touched.firstName &&
                                    errors.firstName}
                                </span>
                              </div>

                              <div className="from-fild">
                                <div className="icon">
                                  <img alt="user" src="/images/user.png" />
                                </div>
                                <label htmlFor="lastName">Last Name*</label>
                                <input
                                  className={`${errors.lastName &&
                                    touched.lastName &&
                                    errors.lastName
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  onChange={(e) =>
                                    capatilizeLetters(
                                      e,
                                      "lastName",
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                  type="text"
                                  id="lastName"
                                  name="lastName"
                                  placeholder="Last Name"
                                />
                                <span className="error">
                                  {errors.lastName &&
                                    touched.lastName &&
                                    errors.lastName}
                                </span>
                              </div>


                              <div className="from-fild">
                                <div className="icon">
                                  <img alt="phone" src="/images/phon.png" />
                                </div>
                                <label htmlFor="phone">Phone</label>
                                <PhoneInputField
                                  className={`number-input ${errors.phone && touched.phone && errors.phone
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  // country={userCountryCode.toLowerCase()}
                                  setFieldValue={setFieldValue}
                                  onBlur={handleBlur}
                                  value={values.phone}
                                  id="phone"
                                  name="phone"
                                />
                                <div className="copy-icon">
                               <CopyComponent value={values.phone} />
                                </div>
                                <span className="error">
                                  {errors.phone && touched.phone && errors.phone}
                                </span>
                              </div>


                              <div className="from-fild input-container">
                                <div className="icon">
                                  <img alt="whatsapp" src="/images/whtsp.png" />
                                </div>
                                <label htmlFor="whatsapp">Whatsapp</label>
                                <PhoneInputField
                                  className={`${errors.whatsapp &&
                                    touched.whatsapp &&
                                    errors.whatsapp
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  // country={userCountryCode.toLowerCase()}
                                  setFieldValue={setFieldValue}
                                  onBlur={handleBlur}
                                  value={values.whatsapp}
                                  id="whatsapp"
                                  name="whatsapp"
                                />
                                <div className="copy-icon">
                               <CopyComponent value={values.whatsapp} />
                                </div>
                                <span className="error">
                                  {errors.whatsapp &&
                                    touched.whatsapp &&
                                    errors.whatsapp}
                                </span>
                              </div>
                              <div className="from-fild">
                                <div className="icon">
                                  <img src="/images/australia.png" alt="country" />
                                </div>
                                <label htmlFor="country">
                                  Country<i>*</i>
                                </label>
                                <select
                                  className={`selectmenu-element form-item__element--select has-placeholder ${errors.country &&
                                    touched.country &&
                                    errors.country
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  data-name="selectmenu-1"
                                  // id="my-selectmenu"
                                  onChange={(value) =>
                                    countryChangeHandler(
                                      value,
                                      "country",
                                      setFieldValue
                                    )
                                  }
                                  onBlur={handleBlur}
                                  value={values.country}
                                  type="text"
                                  id="country"
                                  name="country"
                                  placeholder="Country"
                                >
                                  <option disabled="" selected="" value="">
                                    Country<i>*</i>
                                  </option>
                                  {countryData.map((country) => (
                                    <option value={country.country_name}>
                                      {/* <span className="d-none">{country.id}</span> */}
                                      {country.country_name}
                                    </option>
                                  ))}
                                </select>
                                {/* <input
                            className={`${
                              errors.country &&
                              touched.country &&
                              errors.country
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.country}
                            type="text"
                            id="country"
                            name="country"
                            placeholder="Country"
                          /> */}
                                <span className="error">
                                  {errors.country &&
                                    touched.country &&
                                    errors.country}
                                </span>
                              </div>

                              <div className="from-fild">
                                <div className="icon">
                                  <img alt="user" src="/images/user.png" />
                                </div>
                                <label htmlFor="firstName">Screen Name*</label>
                                <input
                                  className={`${errors.screen_name &&
                                    touched.screen_name &&
                                    errors.screen_name
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.screen_name}
                                  type="text"
                                  id="screen_name"
                                  name="screen_name"
                                  placeholder="Screen Name"
                                />
                                <span className="error">
                                  {errors.screen_name &&
                                    touched.screen_name &&
                                    errors.screen_name}
                                </span>
                              </div>
                              {/* /languages */}

                              <div className="from-fild">
                                <div className="icon">
                                  <img src="/images/australia.png" alt="languages" />
                                </div>
                                <label htmlFor="languages">
                                  Languages<i>*</i>
                                </label>
                                {/* {values.languages.length > 0 && ( */}
                                <Select
                                  id="languages"
                                  className="selection-box client-selection-box"
                                  options={formLanguages}
                                  multi={true}
                                  isMulti
                                  onChange={(value) =>
                                    setFieldValue("languages", value)
                                  }
                                  onBlur={handleBlur}
                                  placeholder="Select languages"
                                  value={values.languages}
                                  closeMenuOnSelect={false}
                                  closeMenuOnScroll={false}
                                />
                                {/* )}*/}
                                {/* )} */}
                                {/* <input
                            className={`${
                              errors.languages &&
                              touched.languages &&
                              errors.languages
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.languages}
                            type="text"
                            id="languages"
                            name="languages"
                            placeholder="Languages"
                          /> */}
                                <span className="error">
                                  {errors.languages &&
                                    touched.languages &&
                                    errors.languages}
                                </span>
                              </div>
                              {/* timezone */}
                              <div className="from-fild">
                                <div className="icon">
                                  <img src="/images/australia.png" alt="timezone" />
                                </div>
                                <label htmlFor="timezone">
                                  Timezone<i>*</i>
                                  {/* <img
                                    title="Reload TimeZones"
                                    onClick={(e) => reloadTimeZones("refresh")}
                                    className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                                    alt="Reload TimeZones"
                                    src="/images/refresh.png"
                                  /> */}
                                </label>

                                {/* {timezoneOnly && ( */}
                                {checkTimeZone()}
                                <select
                                  className={`selectmenu-element form-item__element--select has-placeholder ${errors.timezone &&
                                    touched.timezone &&
                                    errors.timezone
                                    ? "error-boundry"
                                    : ""
                                    }`}
                                  // id="my-selectmenu"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.timezone}
                                  type="text"
                                  id="timezone"
                                  name="timezone"
                                  placeholder="Timezone"
                                >
                                  <option disabled="" selected="" value="">
                                    Timezone<i>*</i>
                                  </option>
                                  {newTimezoneData.map((timezone) => (
                                    <option value={timezone.id}>
                                      {/* <span className="d-none">{country.id}</span> */}
                                      {timezone.front_name}
                                    </option>
                                  ))}
                                </select>
                                {/* )} */}
                                {/* <input
                            className={`${
                              errors.languages &&
                              touched.languages &&
                              errors.languages
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.languages}
                            type="text"
                            id="languages"
                            name="languages"
                            placeholder="Languages"
                          /> */}
                                <span className="error">
                                  {errors.timezone &&
                                    touched.timezone &&
                                    errors.timezone}
                                </span>
                              </div>
                              <div className="from-fild clear-both">
                                <div className="icon">
                                  <img alt="user" src="/images/user.png" />
                                </div>
                                <label htmlFor="limit">Limit</label>
                                <input
                                  className="box-sizing-border-box"
                                  // className={`${
                                  //   errors.limit &&
                                  //   touched.limit &&
                                  //   errors.limit
                                  //     ? "error-boundry"
                                  //     : ""
                                  // }`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.limit}
                                  type="text"
                                  id="limit"
                                  name="limit"
                                  placeholder="Limit"
                                />
                                {/* <span className="error">
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </span> */}

                              </div>
                              <div className="from-fild">
                                <div className="icon">
                                  <img alt="user" src="/images/user.png" />
                                </div>
                                <label htmlFor="referral_code">Referral Code</label>
                                <input
                                  className="box-sizing-border-box"
                                  // className={`${
                                  //   errors.limit &&
                                  //   touched.limit &&
                                  //   errors.limit
                                  //     ? "error-boundry"
                                  //     : ""
                                  // }`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.referral_code}
                                  type="text"
                                  id="referral_code"
                                  name="referral_code"
                                  placeholder="Referral Code"
                                />
                                {/* <span className="error">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </span> */}
                              </div>
                              <div className="from-fild ">

                                <label htmlFor="limit">Comment</label>
                                <textarea
                                  className="box-sizing-border-box text-are-client p-2"
                                  rows={14}

                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.comments}

                                  id="comments"
                                  name="comments"
                                // placeholder="Limit"
                                />
                                {/* <span className="error">
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </span> */}

                              </div>
                              <div className="from-fild">

                                <div className="icon">
                                  <img alt="email" src="/images/mail.png" />
                                </div>
                                <label htmlFor="email">Email</label>
                                <input
                                  className={`${errors.email &&
                                    touched.email &&
                                    errors.email
                                    ? "error-boundry"
                                    : ""
                                    }`}

                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="myemail@address.com"
                                />
                                 <div className="copy-icon">
                               <CopyComponent value={values.email} />
                                </div>
                                <span className="error">
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </span>
                              </div>
                              <div className="clear-both">

                                <div className="from-fild">
                                  <div className="icon">

                                  </div>
                                  <label htmlFor="test_mode" className="mb-4">Test Mode</label>
                                  <>
                                    <ToggleTestMode item={values} setFieldValue={setFieldValue} stepOneData={initialValues} />
                                  </>
                                </div>
                              </div>
                              {/* //email */}



                              {valueUpdated && <>
                                <div className="from-inner-box">
                                  <div className="full-fild mt-3">
                                    <label>
                                      Legal and Privacy <i>*</i>
                                    </label>
                                    <span className="error marg-top-55">

                                    </span>
                                    <div className="prvacy-box">
                                      <input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value="I have read and agree to the terms and conditions"
                                        id="termsandconditions"
                                        type="checkbox"
                                        name="agree_to_legal_terms_and_conditionss"
                                        // disabled
                                        checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                      />
                                
                                      <label htmlFor="termsandconditions" className="width-unset">
                                        I have read and agree to the terms and conditions.
                                      </label>
                                      <div className="tr-pr">
                                        <Link to="/terms-conditions" target="_blank">
                                          Terms &amp; Conditions
                                        </Link>
                                        <Link to="/privacy-policy" target="_blank">
                                          Privacy Policy
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="from-inner-box radeo check-box-input payment-saction mt-3">
                                  <div className="full-fild">
                                    <label>
                                      Opt-In? <i>*</i>
                                    </label>
                                    <h4>
                                      I would like to receive notices of offers (for free events,
                                      opportunities, etc.)
                                    </h4>
                                    <div className="check-field">
                                      <input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value="Yes"
                                        id="opt_in"
                                        type="radio"
                                        name="opt_in_out"
                                        checked={values.opt_in_out === "Yes" ? 'checked' : ''}
                                      />
                                      {/* {console.log(values.opt_in_out, "values.opt_in_out ")} */}
                                      <label htmlFor="optin">Yes</label>
                                    </div>
                                    <div className="check-field">
                                      <input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value="No"
                                        id="opt_out"
                                        type="radio"
                                        name="opt_in_out"
                                        checked={values.opt_in_out === "No" ? 'checked' : ''}
                                      />
                                      <label htmlFor="optout">No</label>
                                    </div>
                                  </div>
                                  <span className="error marg-top-55">
                                    {/* {errors.opt_in_out && touched.opt_in_out && errors.opt_in_out} */}
                                  </span>
                                </div>
                              </>}
                              <div className="from-inner-box clear-both">
                                <div className="full-fild mt-5">


                                  <div className="prvacy-box">
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value="I have read and agree to the terms and conditions"
                                      id="zoom_verification"
                                      type="checkbox"
                                      name="zoom_verification"

                                      checked={values.zoom_verification ? 'checked' : ''}
                                    />

                                    <label htmlFor="zoom_verification" className="width-unset">
                                      Able to connect to zoom
                                    </label>

                                  </div>
                                </div>
                              </div>
                              <div className="bution">
                                <button type="submit" className="button-bx">
                                  Update
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
              :
              <> <div className="login-register-saction registernew">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="login-saction-right register-right register-clint">
                        <div className="form-l---r">
                          <Formik
                            initialValues={initialValues}
                            validate={(values) => {
                              const errors = {};
                              if (!values.firstName) errors.firstName = "Required";
                              if (!values.lastName) errors.lastName = "Required";
                              // // if (values.phone && !/^[0-9]+$/.test(values.phone)) {
                              // //   errors.phone = "Invalid Phone";
                              // // }
                              // if (values.whatsapp && !/^[0-9]+$/.test(values.whatsapp)) {
                              //   errors.whatsapp = "Invalid whatsapp";
                              // }

                              if (
                                values.website &&
                                !/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
                                  values.website
                                )
                              ) {
                                errors.website = "Invalid Website";
                              }
                              if (!values.country) errors.country = "Required";
                              if (!values.timezone) errors.timezone = "Required";

                              if (!values.languages) errors.languages = "Required";
                              return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                              RegisterUser(values);
                              // setOpen(true);
                              // setTimeout(() => {
                              //   alert(JSON.stringify(values, null, 2));
                              //   setSubmitting(false);
                              // }, 400);
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <form onSubmit={handleSubmit}>

                                <div className="from-fild">
                                  <div className="icon">
                                    <img alt="user" src="/images/user.png" />
                                  </div>
                                  <label htmlFor="firstName">First Name*</label>
                                  <input
                                    className={`${errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    onChange={(e) =>
                                      capatilizeLetters(
                                        e,
                                        "firstName",
                                        setFieldValue,
                                        values
                                      )
                                    }
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                  />
                                  <span className="error">
                                    {errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName}
                                  </span>
                                </div>

                                <div className="from-fild">
                                  <div className="icon">
                                    <img alt="user" src="/images/user.png" />
                                  </div>
                                  <label htmlFor="lastName">Last Name*</label>
                                  <input
                                    className={`${errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    onChange={(e) =>
                                      capatilizeLetters(
                                        e,
                                        "lastName",
                                        setFieldValue,
                                        values
                                      )
                                    }
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                  />
                                  <span className="error">
                                    {errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName}
                                  </span>
                                </div>

                                <div className="from-fild">
                                  <div className="icon">
                                    <img alt="phone" src="/images/phon.png" />
                                  </div>
                                  <label htmlFor="phone">Phone</label>
                                  <PhoneInputField
                                    className={`number-input ${errors.phone && touched.phone && errors.phone
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    // country={userCountryCode.toLowerCase()}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    id="phone"
                                    name="phone"
                                  />
                                  <span className="error">
                                    {errors.phone && touched.phone && errors.phone}
                                  </span>
                                </div>

                                <div className="from-fild">
                                  <div className="icon">
                                    <img alt="whatsapp" src="/images/whtsp.png" />
                                  </div>
                                  <label htmlFor="whatsapp">Whatsapp</label>
                                  <PhoneInputField
                                    className={`${errors.whatsapp &&
                                      touched.whatsapp &&
                                      errors.whatsapp
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    // country={userCountryCode.toLowerCase()}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    value={values.whatsapp}
                                    id="whatsapp"
                                    name="whatsapp"
                                  />
                                  <span className="error">
                                    {errors.whatsapp &&
                                      touched.whatsapp &&
                                      errors.whatsapp}
                                  </span>
                                </div>
                                <div className="from-fild">
                                  <div className="icon">
                                    <img src="/images/australia.png" alt="country" />
                                  </div>
                                  <label htmlFor="country">
                                    Country<i>*</i>
                                  </label>
                                  <select
                                    className={`selectmenu-element form-item__element--select has-placeholder ${errors.country &&
                                      touched.country &&
                                      errors.country
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    data-name="selectmenu-1"
                                    // id="my-selectmenu"
                                    onChange={(value) =>
                                      countryChangeHandler(
                                        value,
                                        "country",
                                        setFieldValue
                                      )
                                    }
                                    onBlur={handleBlur}
                                    value={values.country}
                                    type="text"
                                    id="country"
                                    name="country"
                                    placeholder="Country"
                                  >
                                    <option disabled="" selected="" value="">
                                      Country<i>*</i>
                                    </option>
                                    {countryData.map((country) => (
                                      <option value={country.country_name}>
                                        {/* <span className="d-none">{country.id}</span> */}
                                        {country.country_name}
                                      </option>
                                    ))}
                                  </select>
                                  {/* <input
                          className={`${
                            errors.country &&
                            touched.country &&
                            errors.country
                              ? "error-boundry"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                          type="text"
                          id="country"
                          name="country"
                          placeholder="Country"
                        /> */}
                                  <span className="error">
                                    {errors.country &&
                                      touched.country &&
                                      errors.country}
                                  </span>
                                </div>

                                <div className="from-fild">
                                  <div className="icon">
                                    <img alt="user" src="/images/user.png" />
                                  </div>
                                  <label htmlFor="firstName">Screen Name*</label>
                                  <input
                                    className={`${errors.screen_name &&
                                      touched.screen_name &&
                                      errors.screen_name
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.screen_name}
                                    type="text"
                                    id="screen_name"
                                    name="screen_name"
                                    placeholder="Screen Name"
                                  />
                                  <span className="error">
                                    {errors.screen_name &&
                                      touched.screen_name &&
                                      errors.screen_name}
                                  </span>
                                </div>
                                {/* /languages */}

                                <div className="from-fild">
                                  <div className="icon">
                                    <img src="/images/australia.png" alt="languages" />
                                  </div>
                                  <label htmlFor="languages">
                                    Languages<i>*</i>
                                  </label>
                                  {/* {values.languages.length > 0 && ( */}
                                  <Select
                                    id="languages"
                                    className="selection-box client-selection-box"
                                    options={formLanguages}
                                    multi={true}
                                    isMulti
                                    onChange={(value) =>
                                      setFieldValue("languages", value)
                                    }
                                    onBlur={handleBlur}
                                    placeholder="Select languages"
                                    value={values.languages}
                                    closeMenuOnSelect={false}
                                    closeMenuOnScroll={false}
                                  />
                                  {/* )}*/}
                                  {/* )} */}
                                  {/* <input
                          className={`${
                            errors.languages &&
                            touched.languages &&
                            errors.languages
                              ? "error-boundry"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.languages}
                          type="text"
                          id="languages"
                          name="languages"
                          placeholder="Languages"
                        /> */}
                                  <span className="error">
                                    {errors.languages &&
                                      touched.languages &&
                                      errors.languages}
                                  </span>
                                </div>
                                {/* timezone */}
                                <div className="from-fild">
                                  <div className="icon">
                                    <img src="/images/australia.png" alt="timezone" />
                                  </div>
                                  <label htmlFor="timezone">
                                    Timezone<i>*</i>
                                  </label>
                                  {/* {timezoneOnly && ( */}
                                  {checkTimeZone()}
                                  <select
                                    className={`selectmenu-element form-item__element--select has-placeholder ${errors.timezone &&
                                      touched.timezone &&
                                      errors.timezone
                                      ? "error-boundry"
                                      : ""
                                      }`}
                                    // id="my-selectmenu"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.timezone}
                                    type="text"
                                    id="timezone"
                                    name="timezone"
                                    placeholder="Timezone"
                                  >
                                    <option disabled="" selected="" value="">
                                      Timezone<i>*</i>
                                    </option>
                                    {newTimezoneData.map((timezone) => (
                                      <option value={timezone.id}>
                                        {/* <span className="d-none">{country.id}</span> */}
                                        {timezone.front_name}
                                      </option>
                                    ))}
                                  </select>
                                  {/* )} */}
                                  {/* <input
                          className={`${
                            errors.languages &&
                            touched.languages &&
                            errors.languages
                              ? "error-boundry"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.languages}
                          type="text"
                          id="languages"
                          name="languages"
                          placeholder="Languages"
                        /> */}
                                  <span className="error">
                                    {errors.timezone &&
                                      touched.timezone &&
                                      errors.timezone}
                                  </span>
                                </div>
                                <div className="from-fild clear-both">
                                  <div className="icon">
                                    <img alt="user" src="/images/user.png" />
                                  </div>
                                  <label htmlFor="limit">Limit</label>
                                  <input
                                    className="box-sizing-border-box"
                                    // className={`${
                                    //   errors.limit &&
                                    //   touched.limit &&
                                    //   errors.limit
                                    //     ? "error-boundry"
                                    //     : ""
                                    // }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.limit}
                                    type="text"
                                    id="limit"
                                    name="limit"
                                    placeholder="Limit"
                                  />
                                  {/* <span className="error">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </span> */}
                                </div>
                                <div className="from-fild">
                                  <div className="icon">
                                    <img alt="user" src="/images/user.png" />
                                  </div>
                                  <label htmlFor="referral_code">Referral Code</label>
                                  <input
                                    className="box-sizing-border-box"
                                    // className={`${
                                    //   errors.limit &&
                                    //   touched.limit &&
                                    //   errors.limit
                                    //     ? "error-boundry"
                                    //     : ""
                                    // }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.referral_code}
                                    type="text"
                                    id="referral_code"
                                    name="referral_code"
                                    placeholder="Referral Code"
                                  />
                                  {/* <span className="error">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </span> */}
                                </div>
                                <div>
                                  {/* <div className="from-fild">
                              <div className="icon">
                        
                              </div>
                              <label htmlFor="test_mode" className="mb-4">Test Mode</label>
                              <>
                                <ToggleTestMode item={values} setFieldValue={setFieldValue} stepOneData={initialValues} />
                              </>
                            </div> */}
                                </div>
                                <div className="from-inner-box clear-both">
                                <div className="full-fild mt-5">


                                  <div className="prvacy-box">
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value="I have read and agree to the terms and conditions"
                                      id="zoom_verification"
                                      type="checkbox"
                                      name="zoom_verification"

                                      checked={values.zoom_verification ? 'checked' : ''}
                                    />

                                    <label htmlFor="zoom_verification" className="width-unset">
                                      Able to connect to zoom
                                    </label>

                                  </div>
                                </div>
                              </div>
                                <div className="bution">
                                  <button type="submit" className="button-bx">
                                    Update
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </>}
          </TabPanel>
          {questioner !== "" && <TabPanel id={1} activeTab={activeTab}>
            <div className="min-height-80vh">
              <br />
              <br />

              {
                questioner !== "" && <>
                  <div>  <span className="font-weight-bolder"> How did you hear about VerySoul? :</span>  <span>{questioner.about_verysoul}</span></div>
                  <br />
                  <br />
                  <div>  <span className="font-weight-bolder">Have you had a fulfilling experience with an Evidential Medium before? :</span> <span>{questioner.past_experience}</span></div>
                  <br />
                  <br />
                  <div>  <span className="font-weight-bolder"> Are you a Medium or psychic? :</span> <span>{questioner.medium_psychic}</span></div>
                  <br />
                  <br />
                  <div>  <span className="font-weight-bolder"> Are you interested in volunteering in other ways for VS? :</span> <span>{questioner.help_text}</span></div>


                </>
              }
            </div>
          </TabPanel>}
        </ul>
      </div>
      {[1, 1, 1, 1].map(() => <div className="p-5 m-5"> </div>)}
    </>
  );
}
